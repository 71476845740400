import { Grid, Paper, Stack, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import React from 'react';
import { Login } from '../components/login';

const LoginPage: React.FC = () => {

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (

        <Grid container direction='column' width={'100vw'} height={'100vh'} alignItems={'center'}>

            <Grid item xs container alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={8} sm={5} md={4} lg={3} xl={2}
                    component={'img'} src={require('../assets/sinbadlogo.webp')} width={'100%'} />
            </Grid>

            <Grid item container direction={'row'} justifyContent={'center'}>

                <Grid container direction={'column'}
                    item xs={11} sm={8} md={6} lg={4} xl={3}>

                    <Grid item component={Paper} elevation={isSm ? 0 : 4} padding={2}>
                        <Stack direction={'column'} alignItems={'center'}>
                            <Typography margin={1} variant='h6'>
                                Manpower Management System
                            </Typography>
                            <Login />
                        </Stack>
                    </Grid>

                    <Grid item component={Typography} marginTop={1}>
                        <Button href='/requestpassword'>
                            Request New Password
                        </Button>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item xs />

            <Grid item component={Stack} direction={'column'} alignItems={'center'}>
                <img src={require('../assets/cvlogo.png')} width={'15%'} />
                <Typography gutterBottom marginTop={2}>
                    Powered by 1Clearview &copy; 2022
                </Typography>
            </Grid>

        </Grid>

    );
};

export default LoginPage;
