import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import LogoutButton from './logout-button';

const NotAuthorised: React.FC<{ message?: string }> = ({ message }) => {
    return (

        <Grid container direction={'column'} width={'100vw'} height={'100vh'} textAlign={'center'}>
            <Grid item xs />
            <Grid item>
                <Stack direction={'column'}>
                    <Typography variant='h1'>
                        {message ?? 'Not Authorised'}
                    </Typography>
                    <LogoutButton />
                </Stack>
            </Grid>
            <Grid item xs />
        </Grid>

    );
};

export default NotAuthorised;
