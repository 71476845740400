import React, { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthProvider, useFirebaseApp, FunctionsProvider } from 'reactfire';
import { getFunctions } from 'firebase/functions';
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';


/**
 * Initialise Firebase components
 * @param param0
 * @returns
 */
export const FirebaseComponents: React.FC<React.PropsWithChildren<any>> = ({ children }) => {

    console.debug('FirebaseComponents');

    const app = useFirebaseApp();
    const auth = getAuth(app);
    const functions = getFunctions(app);
    const analytics = getAnalytics(app);

    // Setup Analytics
    useEffect(() => {

        return onAuthStateChanged(auth, (user) => {

            // Do something funky here to predirect to login?
            if (user === null) return;

            // Setup tracking properties
            console.debug('Set Analytics Props');

            setUserId(analytics, user.uid);

            setUserProperties(analytics, {
                domain: user.email?.split('@').pop() ?? 'N/A',
                app_name: 'mngMme',
            });

        });

    }, []);

    // Render Firebase providers
    return (
        <AuthProvider sdk={auth}>
            <FunctionsProvider sdk={functions}>
                {children}
            </FunctionsProvider>
        </AuthProvider>
    );

};

export default FirebaseComponents;
