import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { signOut } from 'firebase/auth';
import { useAuth } from 'reactfire';
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton: React.FC = () => {

    const auth = useAuth();

    const logout = async () => signOut(auth);

    return (
        <Tooltip title='Logout'>
            <IconButton color='inherit' onClick={logout}>
                <LogoutIcon />
            </IconButton>
        </Tooltip>
    );

};

export default LogoutButton;
