import React from 'react';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';

const Loading: React.FC<{ message?: string }> = ({ message }) => {
    return (

        <Grid container
            direction={'column'} width={'100%'} height={'100%'}
            textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Grid item xs />
            <Grid item>
                <Stack direction={'column'} textAlign={'center'} alignContent={'center'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress />
                    <Typography variant='h6' marginTop={2}>
                        {message ?? 'Loading...'}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs />
        </Grid>

    );
};

export default Loading;
