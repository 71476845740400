


/** Normalise error message */
export const errorMessage = (error: unknown): Error => {

    console.error(error);

    if (error instanceof Error) return error;

    if (error == null) return new Error('Error is null');

    if (error === undefined) return new Error('Error is undefined');

    if (typeof error === 'string') return new Error(error);

    return new Error('Unknown Error');

};
