import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './home';
import AuthedNavigation from '../services/authed-navigation';
import RequestPasswordPage from './request-password';

const AppRoutes: React.FC = () => {

    return (

        <Router>

            <Routes>
                <Route path='/requestpassword' element={<RequestPasswordPage />} />
            </Routes>

            {/* Authenticated Routes */}
            <Routes>
                <Route path='/' element={<AuthedNavigation><Home /></AuthedNavigation>} />
            </Routes>

        </Router>

    );

};

export default AppRoutes;
