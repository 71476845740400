import { useMediaQuery, Paper, Stack, Typography, Button, Grid, useTheme, Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { errorMessage } from '../services/utils';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useAuth } from 'reactfire';
import { useNavigate } from 'react-router-dom';


const RequestPasswordPage: React.FC = () => {

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [busy, setBusy] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuth();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required(),
        }),
        onSubmit: async ({ email }) => {

            setBusy(true);

            try {
                await sendPasswordResetEmail(auth, email);
                enqueueSnackbar('An email has been sent to your inbox, follow the instructions to setup a new password', { variant: 'success' });
                navigate('/');
            } catch (error) {
                enqueueSnackbar(errorMessage(error).message, { variant: 'error' });
            }

            setBusy(false);

        },
    });


    return (

        <Grid container direction='column' width={'100vw'} height={'100vh'} alignItems={'center'}>

            <Grid item xs container alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={8} sm={5} md={4} lg={3} xl={2}
                    component={'img'} src={require('../assets/sinbadlogo.png')} width={'100%'} />
            </Grid>

            <Grid item container direction={'row'} justifyContent={'center'}>

                <Grid container direction={'column'}
                    item xs={11} sm={8} md={6} lg={4} xl={3}>

                    <Grid item component={Paper} elevation={isSm ? 0 : 4} padding={2}>
                        <Stack direction={'column'} alignItems={'center'}>
                            <Typography margin={1} variant='h6'>
                                Request New Password
                            </Typography>

                            <Box component='form' noValidate={true} onSubmit={formik.handleSubmit} marginTop={1}>

                                <TextField
                                    margin='normal'
                                    id='email'
                                    name='email'
                                    autoComplete='email'
                                    autoFocus={true}
                                    type={'email'}
                                    placeholder='Email Address'
                                    disabled={busy}
                                    required={true}
                                    fullWidth={true}
                                    value={formik.values.email}
                                    onChange={formik.handleChange} />

                                <Button
                                    type='submit'
                                    variant='contained'
                                    disabled={formik.isValid === false || busy}
                                    fullWidth={true}
                                    size='large'>
                                    Request Password
                                </Button>

                            </Box>

                        </Stack>

                    </Grid>

                </Grid>

            </Grid>

            <Grid item xs />

            <Grid item component={Stack} direction={'column'} alignItems={'center'}>
                <img src={require('../assets/cvlogo.png')} width={'15%'} />
                <Typography gutterBottom marginTop={2}>
                    Powered by 1Clearview &copy; 2022
                </Typography>
            </Grid>

        </Grid>

    );

};

export default RequestPasswordPage;
