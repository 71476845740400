import React from 'react';
import { useSigninCheck } from 'reactfire';
import LoginPage from '../routes/login';
import NotAuthorised from '../components/not-authorised';
import Loading from '../components/loading';
import { Box } from '@mui/material';

/**
 * Ensures user is logged in before displaying content
 */
const AuthedNavigation: React.FC<React.PropsWithChildren<any>> = ({ children }) => {

    const { status, data: signInCheckResult } = useSigninCheck({ forceRefresh: true, requiredClaims: { mme: 'basic' } });

    if (status === 'loading') return (
        <Box height={'100vh'} width={'100vw'}>
            <Loading message='Loading User...' />
        </Box>
    );

    console.debug(signInCheckResult);

    if (signInCheckResult.signedIn === false) {
        return <LoginPage />;
    }

    if (signInCheckResult.hasRequiredClaims === false) {
        return <NotAuthorised />;
    }

    return children;

};

export default AuthedNavigation;
