import { AppBar, Toolbar, Box, Stack, Container, LinearProgress, useTheme } from '@mui/material';
import React, { useState } from 'react';
import LogoutButton from '../components/logout-button';
import Availability from '../components/availability';

const Home: React.FC = () => {

    const theme = useTheme();
    const [loading, setLoading] = useState(false);

    return (

        <Stack height={'100vh'}>

            <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />

            <AppBar position='static' elevation={0}>
                <Toolbar sx={{ bgcolor: 'white' }}>

                    <img src={require('../assets/sinbadlogo.webp')} height={'50'} />

                    <Box sx={{ flexGrow: 1 }} />

                    <LogoutButton />

                </Toolbar>
            </AppBar>

            <Container maxWidth={'lg'} sx={{ flexGrow: 1, marginTop: theme.spacing(4), padding: 0 }} >
                <Availability onLoading={setLoading} />
            </Container>

        </Stack>

    );

};

export default Home;
