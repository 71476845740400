import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import { useAuth } from 'reactfire';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useSnackbar } from 'notistack';
import { errorMessage } from '../services/utils';

export const Login: React.FC = () => {

    const auth = useAuth();
    const [busy, setBusy] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required(),
            password: Yup.string().required(),
        }),
        onSubmit: async ({ email, password }) => {

            setBusy(true);

            try {
                await signInWithEmailAndPassword(auth, email, password);
            } catch (error) {
                enqueueSnackbar(errorMessage(error).message, { variant: 'error' });
            }

            setBusy(false);

        },
    });

    return (

        <Box component='form' noValidate={true} onSubmit={formik.handleSubmit} marginTop={1}>

            <TextField
                margin='normal'
                id='email'
                name='email'
                autoComplete='email'
                autoFocus={true}
                type={'email'}
                placeholder='Email Address'
                disabled={busy}
                required={true}
                fullWidth={true}
                value={formik.values.email}
                onChange={formik.handleChange} />

            <TextField
                margin='normal'
                id='password'
                name='password'
                autoComplete='current-password'
                type={'password'}
                placeholder='Password'
                disabled={busy}
                required={true}
                fullWidth={true}
                value={formik.values.password}
                onChange={formik.handleChange} />

            <Button
                type='submit'
                variant='contained'
                disabled={formik.isValid === false || busy}
                fullWidth={true}
                size='large'>
                Login
            </Button>

        </Box>

    );

};
