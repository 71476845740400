import React, { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { Button, Grid, TextField, Typography, FormControl, Select, MenuItem, InputLabel, useTheme, Paper, Stack } from '@mui/material';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { DateTime } from 'luxon';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useInterval from '../services/use-interval';
import Loading from '../components/loading';

type TMmeResponse = {
    updated: string;
    detail: Array<Record<string, any>>,
    byLocation: Array<{ name: string, records: number }>;
};

type TAvailabilityArgs = {
    onLoading: (value: boolean) => void;
}

const Availability: React.FC<TAvailabilityArgs> = ({ onLoading: setLoading }) => {

    const theme = useTheme();
    const functions = useFunctions();
    const fn = httpsCallable<null, TMmeResponse>(functions, 'getMmeAvailability');
    const [data, setData] = useState<TMmeResponse>();
    const [date, setDate] = useState<DateTime>(DateTime.now());
    const [locationName, setLocationName] = useState<string>('');
    const analytics = getAnalytics();

    /** Get data on initial load */
    useInterval(getData, 60000);
    useEffect(() => getData(), []);

    function getData() {

        logEvent(analytics, 'mmeGetData');

        setLoading(true);

        fn().then(response => {
            console.debug(response.data);
            setData(response.data);
        }).finally(() => setLoading(false));

    }

    const filteredData = (): Record<string, any>[] => {

        if (data?.detail == undefined) return [];

        logEvent(analytics, 'mmeFilterData', {
            locationName,
            date: date.toISODate(),
        });

        return data.detail.filter(rec => {

            return date?.hasSame(DateTime.fromISO(rec.date), 'day') &&
                rec.locationName.includes(locationName);

        });

    };

    if (data === undefined) return <Loading message="Loading Data from 1CV..." />;


    return (

        <Grid container direction={'column'} height={'100%'} rowSpacing={1}>

            <Grid container direction={'row'} alignItems={'center'} columnGap={1} rowGap={2} padding={{ xs: 2, sm: 0 }} marginBottom={2}>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select fullWidth
                            labelId='location-label'
                            label='Location'
                            value={locationName} onChange={ev => setLocationName(ev.target.value as string)}>

                            {data &&
                                data.byLocation.sort((a, b) => a.name > b.name ? 1 : -1).map(location =>
                                    <MenuItem key={location.name} value={location.name}>
                                        {location.name}
                                    </MenuItem>
                                )
                            }

                        </Select>
                    </FormControl>
                </Grid>

                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} xs />

                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Button onClick={() => setDate(date.minus({ day: 1 }) ?? null)}>
                        {date.minus({ day: 1 }).toFormat('dd-MMM-yy')}
                    </Button>
                </Grid>

                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Button onClick={() => setDate(DateTime.now())}>
                        Today
                    </Button>
                </Grid>

                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Button onClick={() => setDate(date.plus({ day: 1 }) ?? null)}>
                        {date.plus({ day: 1 }).toFormat('dd-MMM-yy')}
                    </Button>
                </Grid>

                <Grid item sm={false} md={false} lg={false} xs>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            label={'Select Date'}
                            showTodayButton={true}
                            value={date}
                            inputFormat='dd-MMM-yy'
                            onChange={newValue => setDate(newValue ?? DateTime.now())}
                            renderInput={(params) => <TextField fullWidth {...params} />} />
                    </LocalizationProvider>
                </Grid>

                {/* <Grid item>
                    <IconButton onClick={() => getData()}>
                        <RefreshIcon />
                    </IconButton>
                </Grid> */}

            </Grid>

            {
                locationName === '' &&
                <Grid item xs component={Typography} variant={'h4'} textAlign={'center'} marginTop={theme.spacing(6)}>
                    Select Location to Display Availability
                </Grid>
            }

            {
                locationName &&
                <Grid item xs className="ag-theme-material" component={Paper} elevation={1}>
                    <AgGridReact
                        rowData={filteredData()}
                        reactUi={true}
                        suppressCellSelection={true}>
                        <AgGridColumn
                            field="locationName"
                            headerName="Location"
                            flex={1} sortable={true} filter={true} />

                        <AgGridColumn
                            field="nationality"
                            sortable={true} filter={true} />

                        <AgGridColumn
                            field="roles"
                            sortable={true} filter={true} />

                    </AgGridReact>
                </Grid>
            }

            {
                data?.updated &&
                <Grid item>

                    <Stack direction={'row'} justifyContent={'space-between'}>

                        <Typography variant='caption'>
                            Last Update: {DateTime.fromISO(data.updated).toFormat('dd-MMM-yy HH:mm:ss')}
                        </Typography>

                        <Typography variant='caption'>
                            Powered by 1Clearview
                        </Typography>

                    </Stack>

                </Grid>
            }

        </Grid >
    );

};

export default Availability;
