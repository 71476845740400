import { useState, useEffect } from 'react';
import { FirebaseOptions } from '@firebase/app-types';
import axios from 'axios';

export const useFirebaseConfig = () => {

    const [config, setConfig] = useState<FirebaseOptions>();

    /**
     * Get the config object from firebase hosting, or return the default
     */
    useEffect(() => {

        const defaultConfig = {
            apiKey: 'AIzaSyDo2bqXP2HtazHtTTBSB4z9zkn728UE5nA',
            authDomain: 'seaview-pmsc.firebaseapp.com',
            databaseURL: 'https://seaview-pmsc.firebaseio.com',
            projectId: 'seaview-pmsc',
            storageBucket: 'seaview-pmsc.appspot.com',
            messagingSenderId: '275541350690',
            appId: '1:275541350690:web:2f5f280f40ff023632ed7b',
            measurementId: 'G-WT1RMYW111'
        };

        axios.get('/__/firebase/init.json').then(({ data }) => {
            console.debug('setConfig', data);
            setConfig(data);
        }).catch(() => setConfig(defaultConfig));

    }, []);

    return { config };

};
