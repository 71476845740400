import { createTheme } from '@mui/material';



export const theme = createTheme({
    palette: {
        primary: {
            main: '#2c276b'
        }
    }
});
