import React from 'react';
import { useFirebaseConfig } from '../services/use-firebase-config';
import { FirebaseAppProvider } from 'reactfire';
import FirebaseComponents from '../services/firebase-components';
import AppRoutes from './router';
import { theme } from '../services/theme';
import { ThemeProvider } from '@mui/material';
import Loading from '../components/loading';
import { SnackbarProvider } from 'notistack';

const App: React.FC = () => {

    const { config: firebaseConfig } = useFirebaseConfig();

    // Load all app dependencies before loading main app
    if (firebaseConfig === undefined) return <Loading message='Loading Firebase Config' />;

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                    <FirebaseComponents>
                        <AppRoutes />
                    </FirebaseComponents>
                </FirebaseAppProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default App;
